#gallery-section {
    background: white;
    color: black;
}

.gallery-tabs{
    display: flex;
    flex-direction: row;
    align-items: end;
    height: max-content;
    gap: 0.25rem;
}

.gallery-tab-btn {
    cursor: pointer;
    background-color: transparent;

    border: 1px solid var(--crimson);
    border-bottom-color: transparent;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    
    padding: 0.75rem;
}
.gallery-tab-btn:hover{
    background-color: var(--gold);
}
.gallery-tab-btn.active {
    border: 3px solid var(--glass);
    border-bottom: none;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    padding-bottom: 1.75rem;
    background-color: var(--crimson);
    color: var(--gold-alt);
    box-shadow: 0 0 1rem var(--shadow);
}

#gallery {
    display: grid;
    grid-template-columns: repeat( auto-fill, minmax( 4, 1fr ) );
    max-width: 50rem;

    margin-top: -1rem;
    padding: 0.5rem;
    gap: 0.75rem;

    background-color: var(--crimson);
    border-radius: 1.25rem;
    border: 3px solid var(--glass);
    box-shadow: 0 0 1rem var(--shadow);
}

.image-frame {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    
    background-color: var(--gold);

    border: 0.25rem solid var(--gold);
    border-radius: 0.75rem;
}
.image-frame:hover {
    transform: scale(1.01);
    box-shadow: 0 0 1rem var(--shadow);
}

.gallery-image {
    width: 100%;
    height: auto;
    object-fit: cover;

    border: 3px solid var(--gold-alt);
    border-radius: 0.5rem;
	cursor: pointer;
}

.image-caption {
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    color: black;
}

/* Special handling for landscape images */
.landscape {
    grid-column: span 2; /* Landscape images span 2 columns */
}
  
.portrait {
    grid-column: span 1; /* Portrait images fit in 1 column */
}

/* Media Queries for responsiveness */
@media (min-width: 1024px) {
    #gallery {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 468px) {
    #gallery {
        grid-template-columns: repeat(2, 1fr);
        border-radius: 0 0 1.25rem 1.25rem;
    }
}

/* Zoom modal */
.zoom-modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: var(--shadow);
    -webkit-backdrop-filter: blur(1rem);
    backdrop-filter: blur(1rem);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
	overflow: hidden;
}

.zoom-modal-content {
    height: 75%;
	width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
	margin-right: -3.5rem;
}

.zoomed-image {
	max-width: 100%;
    max-height: 100%;
	object-fit: scale-down;
    border: 3px solid var(--glass);
    border-radius: 0.5rem;
    box-shadow: 0 0 1rem var(--shadow);
}

.close-modal-btn {
    cursor: pointer;
    position: relative;
    top: 0;
    right: 0;
    min-height: 3rem;
    min-width: 3rem;
    align-content: center;
    margin: 0.25rem;

    background-color: var(--crimson-glass);
    color: white;
    box-shadow: 0 0 1rem var(--shadow);

    border: 3px solid var(--shadow);
    border-radius: 0.25rem;
}
#close-btn {
    color: white;
}

.close-modal:hover {
    background: rgba(255, 255, 255, 0.8);
    color: black;
}

/* Animation for modal */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}