#footer-section {
    background-color: black;
    color: white;
}

#footer-logo {
    max-width: 30%;
    min-width: 20%;
    height: auto;
    margin-bottom: 1rem;
}

#footer-section .section-title{
    margin-bottom: 0.5rem;
}

#footer-section input {
    background-color: transparent;
    color: white;
}
#footer-section input:focus {
    background-color: var(--crimson);
}

#footer-csuf-logo {
    width: 20rem;
    background-color: white;
    margin-bottom: -0.5rem;
    border-radius: 0.5rem;
}

@media only screen and (max-width: 600px) {
    #footer-logo {
        max-width: 100%;
    }
}