#navbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;

    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;

    padding: 0.5rem 0 0.5rem 0;

    background-color: var(--crimson-glass);
    border-bottom: 2px solid var(--glass);

    -webkit-backdrop-filter: blur(2rem);
    backdrop-filter: blur(2rem);
}  



.nav-btn {
	display: flex;
	justify-content: center;
	align-items: center;

    cursor: pointer;
    color: white;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    border-radius: 0.25rem;

    background-color: transparent;
    border: 2px solid var(--glass);
}
.nav-btn:hover {
    background-color: var(--glass);
    box-shadow: 0 0 0.5rem var(--shadow);
}

@media only screen and (max-width: 768px) {
    .nav-btn:not(:nth-child(1)):not(:nth-last-child(1)) {
        display: none;
    }

    #navbar {
        padding: 0.5rem 1rem 0.5rem 1rem;
        justify-content: space-between;
    }
}