#home-section {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 4rem;

    background-color: var(--crimson);
    color: white;
}

#logo {
    max-width: 30%;
    min-width: 20%;
    height: auto;
}

.signup-form {
    padding: 1rem;
}

#home-signup-form-container {
    height: 0;
    opacity: 0;
    overflow: hidden;
    margin-bottom: -1rem;
}
#home-signup-form-container.show {
    height: auto;
    opacity: 1;
    margin-bottom: 0;
}

#home-section input {
    background-color: transparent;
    color: var(--gold);
}
#home-section input:focus {
    background-color: black;
}

@media only screen and (max-width: 600px) {
    #logo {
        max-width: 100%;
    }
}