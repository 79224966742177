* {
    margin: 0; padding: 0;
    transition: all 0.3s ease;
    text-decoration: none;
    box-sizing: border-box;
    font: inherit;
}

:root {
    /* SUM Colors */
    --crimson: #9b1626;
    --crimson-alt: #74000d;
    --gold: #FFB923;
    --gold-alt: #ffe09d;
    --bg: black;
    --text: black;
    --glass: rgba(255, 255, 255, 0.2);
    --crimson-glass: rgba(155, 22, 38, 0.9);
    --shadow: rgba(0, 0, 0, 0.2);
}

body {
    margin: 0;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--bg);
    color: var(--text);
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
	overflow: auto;

    gap: 1rem;
    padding: 3rem 1rem;   /* Top, right, bottom, left */
}

.section-title {
    font-size: 2.5rem;
    margin-bottom: 1.25rem;
}

.btn {
    cursor: pointer;
    padding: 1rem;
    color: black;
    background-color: var(--gold);
    border: 3px solid var(--glass);
    border-radius: 0.25rem;
    font-weight: 600;
    box-shadow: 0 0 1rem var(--shadow);
}
.btn:hover {
    color: var(--crimson);
    transform: scale(1.05);
    box-shadow: 0 0 1rem var(--gold);
}
.btn:active {
    color: white;
    box-shadow: 0 0 1rem black;
    transform: scale(1);
}

.portrait {
    border: 0.25rem solid white;
    border-radius: 0.5rem;
}

/* Icons */
.icons {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    background-color: white;
    border-radius: 0.5rem;
    padding: 0.25rem;
    max-width: fit-content;
    box-shadow: 0 0 1rem var(--glass);
    border: 2px solid var(--gold);
}
a {
    height: 2rem;
}

.icon {
    cursor: pointer;
    height: 2rem;
    width: auto;
    color: var(--crimson-alt);
}
.icon:hover {
    transform: scale(1.1);
}

.glass-card {
    padding: 0.5rem;
    -webkit-backdrop-filter: blur(3rem);
    backdrop-filter: blur(3rem);
    background-color: var(--glass);
    border: 3px solid var(--glass);
    border-radius: 0.5rem;
}
.glass-card:hover {
    transform: scale(1.025);
    box-shadow: 0 0 1rem var(--shadow);
}

/* Sign up forms */
.signup-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
input {
    width: 15rem;
    padding: 0.5rem 1rem;
    border: 1px solid var(--gold);
    border-radius: 0.5rem;
}
input:hover {
    box-shadow: 0 0 0.75rem var(--gold);
}

/* Background phoenix logo */
.background-phoenix {
    position: absolute;
    top: 1;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 150%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    
    opacity: 0.25;
    z-index: 0;
}