#about-section {
    background: linear-gradient(to bottom, white, var(--gold));
    color: black;
    gap: 1rem;
}

#about-phoenix {
	position: absolute;
    width: 100%;
	height: 100%;
	overflow: hidden;
    z-index: 0; /* Push behind the text content */
    pointer-events: none; /* Prevent interaction with the SVG */
}
.background-phoenix {
	fill: var(--crimson);
	max-height: 100%;
}

.about-card {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 100%;
    width: 37rem;
}

.about-header{
    font-size: 2rem;
    font-weight: 500;
    color: var(--crimson-alt);
}

#motto {
    font-weight: bold;
    font-size: 1.5rem;
    padding: 0.75rem 1rem;
    background-color: var(--crimson);
    color: white;
    border: 3px solid var(--glass);
    border-radius: 0.25rem;
    box-shadow: 0 0 2rem white;

    z-index: 1;
}

.about-p {
    text-align: center;
    max-width: 100%;
    font-size: 1.1rem;
}

#about-section ul{
    text-align: center;
    font-size: 1.1rem;
    max-width: 100%;
    margin-left: 2rem;
    margin-right: 1rem;
}

#pillars {
    display: grid;
    grid-template-columns: repeat(2, minmax(150px, 1fr));
    justify-content: space-evenly;
    gap: 0.75rem;
}

.pillar {
    background-color: var(--crimson);
    color: white;
    padding: 1rem;
    border: 3px solid var(--glass);
    border-radius: 0.25rem;
	box-shadow: 0 0 0.5rem white;
}

@media only screen and (max-width: 768px) {
    .background-phoenix {
        min-height: 100%;
		width: 300%;
    }
}