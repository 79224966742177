#events-section{
    background-color: var(--crimson);
    color: white;
}

#events-container{
    display: grid;
    gap: 1rem;
    max-width: 100%;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    justify-items: center;
}

.event-box {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;

    background-color: var(--crimson-alt);
    border: 3px solid var(--glass);
    border-radius: 0.75rem;
    box-shadow: 0 0 1rem var(--shadow);
}
.event-box:hover {
    transform: scale(1.025);
    box-shadow: 0 0 1rem var(--crimson-alt);
}

.event-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-width: 8rem;

    background-color: black;

    padding: 1rem;
    border-radius: 0rem;
}
.day {
    font-size: 2.5rem;
}
.month {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.event-details {
    display: flex;
    flex-direction: column;
    text-align: start;
    justify-content: center;
    padding: 1rem;
    gap: 0.5rem;
}
.event-name {
    font-size: 1.75rem;
}

@media (max-width: 768px) {
    #events-container {
        grid-template-columns: 1fr; /* Single column on smaller screens */
    }
}