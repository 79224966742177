#chat-window {
	position: fixed;
	bottom: 1rem;
	right: 1rem;
	z-index: 1000;
	
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0.5rem;
	gap: 0.5rem;
	
	width: calc(4.5rem - 2px);
	height: calc(4.5rem - 2px);
	max-height: calc( 100% - 5rem );

	background-color: var(--shadow);
	-webkit-backdrop-filter: blur(36px);
	backdrop-filter: blur(36px);

	border: 3px solid var(--glass);
	border-radius: 2rem;
}

#chat-window.open {
	width: 25rem;
	height: 40rem;

	bottom: 1rem;
	right: 1rem;

	border-radius: 2rem;
}

#phoenix-profile {
	color: var(--glass);
}

#conversation {
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
	overflow-y: scroll;

	flex: 1;
	padding: 0.5rem;

	width: 100%;
	min-height: 0;

	color: white;

	border-top: 2px solid var(--glass);
	border-bottom: 2px solid var(--glass);
	border-radius: 1.5rem;
	/* clip-path ensures everything, including the scrollbar, is clipped to the rounded edges */
	clip-path: inset(0 round 1.5rem);
}

#conversation::-webkit-scrollbar {
	width: 6px;
}
#conversation::-webkit-scrollbar-thumb {
    background-color: var(--crimson);
    border-radius: 3px;
	box-shadow: 0 0 1rem var(--shadow);
    cursor: pointer;
}
#conversation::-webkit-scrollbar-thumb:hover {
    background-color: var(--gold);
}

#conversation::-webkit-scrollbar-track {
    background-color: transparent;
}

.message {
	border-radius: 1rem;
	background-color: var(--gold);
	color: black;
	opacity: 0.9;

	box-shadow: 0 0 0.5rem var(--shadow);
	border: 2px solid var(--shadow);

	padding: 0.5rem 0.75rem;
	max-width: 80%;

	align-self: flex-start;

	animation: messagePopup 0.4s ease forwards;
}

.message.user {
	background-color: var(--crimson-alt);
	color: white;
	align-self: flex-end;

	animation: messagePopup 0.4s ease forwards;
}

.message.typing {
	font-style: italic;
	opacity: 0.7;
	animation: messagePopup 0.4s ease forwards;
}

#message-input-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	overflow: hidden;

	width: calc( 100% - 3.5rem );
	height: 3rem;
	margin-right: auto;

	padding: 0.1rem;
	padding-left: 1rem;

	background-color: var(--shadow);
	color: white;
	box-shadow: 0 0 0.5rem var(--shadow);
	
	border: 3px solid var(--gold);
	border-radius: 1.5rem;
}

#message-input {
	flex: 1;
	background: none;
	border: none;
	outline: none;
	box-shadow: none;
	color: white;
	padding: 0.25rem;
	padding-right: 1rem;
}
#message-input::placeholder {
	color: var(--glass);
	opacity: 1;
}

#send-btn-container {
	width: 2.5rem;
	height: 2.5rem;
	border: 3px solid var(--glass);
	border-radius: 2rem;

	background-color: var(--gold);
	box-shadow: 0 0 0.5rem var(--shadow);

	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;
}
#send-btn-container:hover {
	background-color: white;
}

#send-btn-icon {
	width: 1.5rem;
	height: 1.5rem;
	align-content: center;
	fill: black;
}

#chatbot-icon-container{
	cursor: pointer;
	position: fixed;
	bottom: 0.5rem;
	right: 0.5rem;

	width: 3rem;
	height: 3rem;

	display: flex;
	justify-content: center;
	align-content: center;
	overflow: visible;

	background-color: var(--crimson-alt);
	box-shadow: 0 0 0.5rem var(--gold);

	border: 2px solid var(--crimson);
	border-radius: 50%;
}
#chatbot-icon-container:hover {
	transform: scale(1.1);
	box-shadow: 0 0 1rem white;
}

#chatbot-icon {
	min-width: 120%;
	fill: var(--gold);
	transition-duration: 0s;
}
#chatbot-icon:hover {
	fill: white;
}

/* Animations */
@keyframes messagePopup {
	from {
		opacity: 0;
		transform: translateY(20px);
	}
	to {
		opacity: 0.9;
		transform: translateY(0);
	}
}

@media only screen and (max-width: 768px) {
    #chat-window {
		max-width: calc( 100% - 2rem );
    }
}